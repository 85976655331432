@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Modernia';
  src: url('./fonts/Modernia/Modernia-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DotGothic16';
  src: url('./fonts/DotGothic16/DotGothic16-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

#root {
  min-width: 400px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

body {
  min-width: 100vw;
}

.font-modernia {
  font-family: 'Modernia', sans-serif;
  letter-spacing: 0.2em;
}

.font-dot-gothic-16 {
  font-family: 'DotGothic16', sans-serif;
  letter-spacing: 0.2em;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: inherit;
  height: 100%;
  box-sizing: border-box;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.reserved-space::after {
  content: attr(data-text);
  font-weight: bold;
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
}